import React, { useEffect } from 'react';
import { Modal, RRLinkButton } from '@vtblife/uikit';
import { V1VerificationStatus as KycVerificationStatus } from '@vtblife/client-verification-api/axios-gen/kyc_verification_service';

import styles from './kyc-verification-reminder.module.css';
import { KYC_VERIFICATION_LINK } from './constants';
import { sendKycReminderAnalyticsEvent } from './utils';

const imageSrc = '/assets/file-upload-server/0056901ec9bffbaaf50294a1234d95d5.svg';

export const KycVerificationModal: React.FC<{
    status: KycVerificationStatus;
    isBlocker: boolean;
    onClose: VoidFunction;
    cdnUrl: string;
}> = ({ status, isBlocker, onClose, cdnUrl }) => {
    const isAdditionalDataStatus = status === 'STATUS_ADDITIONAL_DATA_REQUIRED';

    useEffect(() => {
        sendKycReminderAnalyticsEvent('verification_modal_show');
    }, []);

    const handleClose = () => {
        sendKycReminderAnalyticsEvent('verification_modal_close');
        onClose();
    };
    return (
        <Modal size="s" opened onClose={handleClose} closable={!isBlocker}>
            <div className={styles.modalImgContainer}>
                <img src={`${cdnUrl}${imageSrc}`} alt="smiling cat" />
            </div>
            <Modal.Title>
                {isAdditionalDataStatus ? <>Нужно внести изменения в&nbsp;анкету</> : 'Подтвердите свою личность'}
            </Modal.Title>
            <div className={styles.modalText}>
                {isAdditionalDataStatus ? (
                    <>
                        Мы&nbsp;нашли неточности в&nbsp;процессе проверки. Внесите необходимые изменения чтобы завершить
                        верификацию
                    </>
                ) : (
                    <>
                        Это повысит шансы на&nbsp;одобрение. Банк ВТБ скоро перестанет принимать заявки от&nbsp;агентов
                        без&nbsp;подтверждённой личности.
                        <ul className={styles.modalList}>
                            <li>Проверка займёт 2&nbsp;минуты;</li>
                            <li>Нужен только паспорт и&nbsp;ваше фото.</li>
                        </ul>
                    </>
                )}
            </div>
            <Modal.Actions>
                <RRLinkButton
                    onClick={() => {
                        sendKycReminderAnalyticsEvent('verification_modal_confirm');
                    }}
                    fullWidth
                    href={KYC_VERIFICATION_LINK}
                    target="blank"
                >
                    {isAdditionalDataStatus ? <>Перейти в&nbsp;профиль</> : 'Подтвердить'}
                </RRLinkButton>
            </Modal.Actions>
        </Modal>
    );
};
